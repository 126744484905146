
const { Chess } = require("chess.js");
import { CountDownTimer } from "./timer.js";
import { loadFile } from "./datahelpers.js";
import SoundManager from "./sounds.js";
import { getPieceImagePath } from "./images.js";
import { LeaderboardManager } from "./leaderboard.js";

import positionsFileUrl from "url:../data/positionsets/allsets.csv";


// TODO: arg validation for methods

/*
 * The CapturesGame class runs the game.
 */
export class CapturesGame {

    #board;
    #score = 0;
    #boardsCompleted = 0;
    #totalNumClicks;
    #numCorrectClicks;

    #leaderboardManager = new LeaderboardManager();

    // An array of FENs to use in the game; positions are
    // loaded from a file in the constructor
    #positionFENs = [];

    #positionsLoaded = false;

    #positionsLoadedCallbacks = [];
    #whenPositionsAreLoaded(callback) {
        if (this.#positionsLoaded)
            callback();
        else
            this.#positionsLoadedCallbacks.push(callback);
    }

    #soundManager;

    /*
     * The constructor takes a Board instance.
     */
    constructor(board) {
        this.#board = board;
        // load positions
        loadFile(positionsFileUrl).then(text => {
            const lines = text.split(/[\n\r]+/);
            const chess = new Chess();
            for (let line of lines) {
                // ignore comments and blank lines
                if (/^(#|\/\/)/.test(line) || /^\s*$/.test(line))
                    continue;

                const fen = line.split(",")[0];
                const result = chess.validate_fen(fen);
                if (!result.valid) {
                    console.log(`"${line}": FEN validation failed; ignoring: ${JSON.stringify(result)}`);
                } else {
                    chess.load(fen);
                    if (chess.turn() == "b")
                        console.log(`"${fen}": positions with black to move not currently allowed`);
                    else
                        this.#positionFENs.push(fen);
                }
            }
            this.#positionsLoaded = true;
            for (let callback of this.#positionsLoadedCallbacks)
                callback();
        });

        this.#soundManager = new SoundManager();

        this.#initializeGame();
    } // end constructor

    #positionsHistory = [];
    #getNewChessPosition() {
        if (!this.#positionsLoaded)
            throw new Error("positions not yet loaded; use #arePositionsLoaded() to check this next time");


        const getRandomPosition = () => {
            return this.#positionFENs[Math.floor(Math.random() * this.#positionFENs.length)];
        };

        let positionFEN = getRandomPosition();
        while (this.#positionsHistory.includes(positionFEN))
            positionFEN = getRandomPosition();

        this.#positionsHistory.push(positionFEN);
        if (this.#positionsHistory.length > this.#positionFENs.length*0.8)
            this.#positionsHistory.shift();

        return positionFEN;
    }

    // Histories of past clicks. Reset for each new position.
    #tmpClicksHistory = [];
    #tmpCorrectClicksHistory = [];

    #initialized = false;
    #initializeGame() {
        for (let soundName of ["explosion", "scratch", "beep", "scrape"]) {
            this.#soundManager.loadSound(soundName);
            this.#soundManager.sounds[soundName].volume(0.07);
        }

        // TODO: optimize this by caching for each position loaded
        // TODO: consider returning a Set and using Sets for things
        const getCorrectSquares = () => {
            // get square names and remove duplicates
            return [...new Set( this.#board.getAttacks().map(move => move.split("x")[1].substring(0,2)) )];
        }

        this.#board.setSquareClickabilityStrategy(
            squareName => {
                const piece = this.#board.getPieceOn(squareName);
                return piece.color === "b" && piece.type !== "k";
            }
        );
        this.#board.addClickableSquareMousedownListener(squareName => {
            if (this.#tmpClicksHistory.includes(squareName))
                return;  // we don't want to allow a click twice
                         // TODO: consider using different logic, maybe making pieces unclickable in Board once clicked
            this.#tmpClicksHistory.push(squareName);
            this.#totalNumClicks += 1;

            if (this.#board.isPieceAttacked(squareName)) {
                this.#tmpCorrectClicksHistory.push(squareName);
                this.#numCorrectClicks += 1;
                this.#score += 1;
                this.#board.setPieceState(squareName, "checked");
                this.#soundManager.playSound("explosion");
                this.#board.makeExplosion(squareName);

                // get correct clicks so far and all correct squares for the current
                // position, filtering duplicates and sorting (for comparison purposes).
                const clicks = [...new Set(this.#tmpCorrectClicksHistory)].sort();
                const squares = [...new Set(getCorrectSquares())].sort();
                if (squares.every((squareName, index) => squareName == clicks[index])) {
                    // go to next board
                    this.#nextBoardPosition();
                }
            } else {
                this.#score -= 1;
                this.#board.setPieceState(squareName, "crossed");
                this.#soundManager.playSound("scratch");
            }

            document.getElementById("score-readout").innerHTML = this.#score;
        });

        this.#board.setAnimateSetPosition(false);

        this.#initialized = true;
        // TODO: this needs a little testing sometime
        for (let callback of this.#initializedCallbacks)
            callback();
    }

    #initializedCallbacks = [];
    #whenInitialized(callback) {
        if (this.#initialized)
            callback();
        else
            this.#initializedCallbacks.push(callback);
    }

    start() {
        this.#whenInitialized(() => {

            document.getElementById("top-bar-buttons-container").classList.add("hidden");
            document.getElementById("pause-button").innerHTML = "Pause";

            document.getElementById("game-end-screen").classList.add("hidden");

            // reset score and num boards completed
            this.#score = 0;
            this.#boardsCompleted = 0;
            document.getElementById("score-readout").innerHTML = this.#score;
            document.getElementById("board-number-readout").innerHTML = this.#boardsCompleted + 1;
            // reset clicks history
            this.#tmpClicksHistory = [];
            this.#tmpCorrectClicksHistory = [];
            this.#totalNumClicks = 0;
            this.#numCorrectClicks = 0;
            // don't reset positions history because we use that across games

            this.#timer = new CountDownTimer(80 * 1000);
            document.getElementById("time-readout").innerHTML = "80";

            this.#whenPositionsAreLoaded(() => {
                this.#board.hidePieces();

                const boxChecked =
                    document.getElementById("flip-board-checkbox")
                            .classList
                            .contains("checked");
                const boardFlipped = this.#board.isFlipped();
                if ( (boxChecked && !boardFlipped) || (!boxChecked && boardFlipped) )
                    this.#board.flip();

                this.#board.setAnimateSetPosition(false);
                this.#board.setPosition(this.#getNewChessPosition());
                this.#board.setAnimateSetPosition(true);

                setTimeout(() => {
                    const display = document.getElementById("big-text-display");
                    display.style.transition = "";
                    display.innerHTML = "";
                    display.style.opacity = "1";
                    this.#doCountdown(3, () => {
                        this.#soundManager.playSound("scrape");
                        this.#board.setEnabled(true);
                        this.#board.unhidePieces();

                        display.innerHTML = "Go!";
                        display.style.transition = "opacity 0.1s 0.4s";
                        display.style.opacity = "0";

                        this.#timerDoneListeners = [];
                        this.#startTimer();
                        document.getElementById("top-bar-buttons-container")
                            .classList.remove("hidden");

                        this.addTimerDoneListener(() => {
                            this.end();
                            this.processScores();
                            this.showGameEndScreen();
                        });
                    });
                }, 600);
            });

        });
    }

    #paused = false;
    pause() {
        if (this.#timer.isRunning())
            this.#timer.stop();
        this.#paused = true;

        this.#board.hidePieces();
        document.getElementById("pause-button").innerHTML = "Resume";
    }

    unpause() {
        if (!this.#timer.isRunning())
            this.#timer.start();
        this.#paused = false;

        this.#board.unhidePieces();
        document.getElementById("pause-button").innerHTML = "Pause";
    }

    togglePause() {
        if (this.#paused)
            this.unpause();
        else
            this.pause();
    }

    end() {
        if (this.#timer.isRunning())
            this.#timer.stop();
        clearInterval(this.#timerCheckerRef);

        this.#board.setAnimateSetPosition(false);
        this.#board.setEnabled(false);
    }

    processScores() {
        document.getElementById("boards-stat-number").innerHTML = this.#boardsCompleted;
        document.getElementById("points-stat-number").innerHTML = this.#score;

        if (this.#totalNumClicks > 0) {
            const accuracy = this.#numCorrectClicks / this.#totalNumClicks;
            const adjustedScore = this.#score * accuracy;
            const levelName = CapturesGame.#calcScoreLevel(adjustedScore);

            const personalScoresLast7Days =
                this.#leaderboardManager.getPersonalScoresDataLastNDays(7);
            const personalScoresAllTime =
                this.#leaderboardManager.getPersonalScoresData();

            const findLeaderboardPosition = (adjustedScore, scoresData) => {
                const clone = object => JSON.parse(JSON.stringify(object));
                const scoresList = clone(scoresData).scores;

                scoresList.sort((a,b) => b.adjustedScore - a.adjustedScore); // sort high-low
                for (let i=0; i<scoresList.length; i++) {
                    if (adjustedScore > scoresList[i].adjustedScore)
                        return i+1;
                }
                return scoresList.length+1;
            }

            document.getElementById("personal-score-l7").innerHTML =
                "<span class=\"small\">#&nbsp;</span>" +
                findLeaderboardPosition(adjustedScore, personalScoresLast7Days);
            document.getElementById("personal-score-all").innerHTML =
                "<span class=\"small\">#&nbsp;</span>" +
                findLeaderboardPosition(adjustedScore, personalScoresAllTime);

            this.#leaderboardManager.submitPersonalScore(this.#score, accuracy);


            document.getElementById("accuracy-stat-number").innerHTML
                = (100.0 * accuracy).toPrecision(4) + "%";

            document.getElementById("level-name-announcement").innerHTML =
                document.querySelector("#level-meter-needle span").innerHTML =
                    levelName;

            document.querySelector("#level-meter-needle img").src =
                getPieceImagePath("b", {
                    "King"   : "k",
                    "Queen"  : "q",
                    "Rook"   : "r",
                    "Bishop" : "b",
                    "Knight" : "n",
                    "Pawn"   : "p"
                }[levelName]);

            document.getElementById("level-meter-needle").style.left =
                Math.min(Math.max(0, adjustedScore)/80 * 100, 100) + "%";

        } else {
            document.getElementById("accuracy-stat-number").innerHTML = "-";

            document.getElementById("personal-score-l7").innerHTML = "-";
            document.getElementById("personal-score-all").innerHTML = "-";

            document.getElementById("level-name-announcement").innerHTML =
                document.querySelector("#level-meter-needle span").innerHTML =
                    "Pawn";

            document.getElementById("level-meter-needle").style.left = "0";
            document.querySelector("#level-meter-needle img").src = getPieceImagePath("b", "p");
        }

    }

    showGameEndScreen() {
        this.#soundManager.playSound("scrape");
        document.getElementById("game-end-screen").classList.remove("hidden");
    }

    static #calcScoreLevel(score) {
        const levelsTable = {
            "King"   : 80,
            "Queen"  : 64,
            "Rook"   : 48,
            "Bishop" : 32,
            "Knight" : 16,
            "Pawn"   : -Infinity
        };

        for (const level in levelsTable)
            if (score >= levelsTable[level])
                return level;
    }

    #timerDoneListeners = [];
    addTimerDoneListener(listener) {
        this.#timerDoneListeners.push(listener);
    }

    #doCountdown(n, callback) {
        if (n <= 0) {
            callback();
            return;
        }
        const display = document.getElementById("big-text-display");
        display.innerHTML = `${n}`;
        this.#soundManager.playSound("beep");
        setTimeout(() => {
            this.#doCountdown(n-1, callback);
        }, 1000);
    }

    #animatedPositionSwitching = true;
    setAnimatedPositionSwitching(aps) {
        this.#animatedPositionSwitching = aps;
    }
    getAnimatedPositionSwitching(aps) {
        return this.#animatedPositionSwitching;
    }
    #nextBoardPosition() {
        // TODO: this is hacky and redundant, in many ways -- fix it

        const updateData = () => {
            this.#tmpCorrectClicksHistory = [];  // reset history for new position
            this.#tmpClicksHistory = [];
            this.#boardsCompleted += 1;
            document.getElementById("board-number-readout").innerHTML = this.#boardsCompleted + 1;
        }

        if (this.#animatedPositionSwitching) {
            this.#board.setAnimateSetPosition(true);
            this.#board.setPosition(this.#getNewChessPosition());
            // TODO: this is super hacky -- let's make the sound relative to the actual animation events
            setTimeout(() => {
                this.#soundManager.playSound("scrape");
                updateData();
            }, 575);
        } else {
            setTimeout(() => {
                this.#board.setAnimateSetPosition(false);
                this.#board.setPosition(this.#getNewChessPosition());
                this.#soundManager.playSound("scrape");
                updateData();
            }, 150)
        }

    }

    #timer;
    #timerCheckerRef;
    #startTimer() {
        // TODO: look at reworking this method
        const timeReadout = document.getElementById("time-readout");
        let lastTimeChecked;
        this.#timerCheckerRef = setInterval(() => {
            const currentTime = Math.ceil(this.#timer.getTimeRemainingInSeconds());
            if (lastTimeChecked != currentTime) {
                // execute once per second

                timeReadout.innerHTML = currentTime;
                if (currentTime == 0) {
                    clearInterval(this.#timerCheckerRef); // TODO: this seems like a sub-optimal way to do it
                    this.#timerDoneListeners.forEach(listener => listener());
                    this.#timerDoneListeners = [];
                } else if (currentTime <= 5) {
                    this.#soundManager.playSound("beep");
                }
            }
            lastTimeChecked = currentTime;
        }, 50);
        this.#paused = false;
        this.#timer.start();
    }
}

