
import Board from "./board.js";
import { CapturesGame } from "./game.js";
import { loadFile } from "./datahelpers.js";
import addButtonClickListeners from "./buttonclicklisteners.js";

import howToPlayUrl from "url:/data/_howtoplay.html";
import closeButtonImgUrl from "url:/images/close.png";
import ccQueenImgUrl from "url:/images/CCQueen.png";

main();
addButtonClickListeners();

function main() {
    document.querySelectorAll(".checkbox").forEach(checkbox => {
        checkbox.addEventListener("click", e => {
            checkbox.classList.toggle("checked");
        });
    });


    document.attackChess = {};

    document.attackChess.board = new Board(document.getElementById("board"));

    document.attackChess.game = new CapturesGame(document.attackChess.board);

    window.addEventListener("resize", () => { document.attackChess.board.resize(); });
    document.attackChess.board.resize();

    document.querySelectorAll("#options-menu .radioish-button").forEach(element => {
        element.addEventListener("click", e => {
            if (e.target.classList.contains("wood-theme")) {
                document.getElementById("board").classList.remove("green-theme", "blue-theme");
                document.querySelector("#options-menu .radioish-button-panel .arrow")
                    .style.left = "16.66%";
            } else if (e.target.classList.contains("green-theme")) {
                document.getElementById("board").classList.add(("green-theme"));
                document.getElementById("board").classList.remove("blue-theme");
                document.querySelector("#options-menu .radioish-button-panel .arrow")
                    .style.left = "50%";

            } else if (e.target.classList.contains("blue-theme")) {
                document.getElementById("board").classList.add("blue-theme");
                document.getElementById("board").classList.remove(("green-theme"));
                document.querySelector("#options-menu .radioish-button-panel .arrow")
                    .style.left = "83.33%";
            }
        });
    });

    loadFile(howToPlayUrl).then(htmlData => {
        const htpb = document.getElementById("how-to-play-box");
        htpb.innerHTML = htmlData;
        const closeButton = document.createElement("img");
        closeButton.classList.add("close-button");
        closeButton.src = closeButtonImgUrl;
        closeButton.addEventListener("click", closeHowToPlay);
        htpb.prepend(closeButton);
        document.querySelector("#how-to-play-box .queen-img").src = ccQueenImgUrl;
    });

    document.querySelectorAll("img").forEach(img => {
        img.draggable = false;
    });

    document.querySelectorAll(".button, .outlined-text").forEach(element => {
        if (! element.classList.contains("no-auto-outlined-text")) {
            element.setAttribute("data-text", element.textContent.trim());

            const textUpdater = new MutationObserver((mutationsList, observer) => {
                element.setAttribute("data-text", element.textContent.trim());
            });
            textUpdater.observe(element, {
                attributes: false,
                childList: true,
                subtree: true
            });
        }
    });
}

export function hideTitleScreen() {
    document.getElementById("title-screen").classList.add("hidden");
}
export function hideGameMenuAndStartGame() {
    document.getElementById("game-menu-container").classList.add("hidden");
    document.attackChess.game.start();
}

export function stopGameAndShowGameMenu() {
    document.attackChess.game.end();
    document.getElementById("game-menu-container").classList.remove("hidden");
}
export function hideGameEndScreenAndShowGameMenu() {
    document.getElementById("game-end-screen").classList.add("hidden");
    document.getElementById("game-menu-container").classList.remove("hidden");
}
export function hideGameEndScreenAndStartGame() {
    document.getElementById("game-end-screen").classList.add("hidden");
    document.attackChess.game.start();
}

export function openOptionsMenu() {
    document.getElementById("options-menu").classList.remove("tucked-away");
    document.getElementById("options-menu-button").classList.add("hidden");
}
export function closeOptionsMenu() {
    document.getElementById("options-menu").classList.add("tucked-away");
    document.getElementById("options-menu-button").classList.remove("hidden");
}

export function updateInstaSwitching() {
    document.attackChess.game.setAnimatedPositionSwitching(
        ! document.getElementById("instaswitch-checkbox").classList.contains("checked")
    );
}

export function openHowToPlay() {
    document.querySelectorAll(".floating-menu-item").forEach(element => {
        element.classList.add("hidden");
    });
    document.getElementById("how-to-play-box").classList.remove("hidden");
}
export function closeHowToPlay() {
    document.querySelectorAll(".floating-menu-item").forEach(element => {
        element.classList.remove("hidden");
    });
    document.getElementById("how-to-play-box").classList.add("hidden");
}

export function openCredits() {
    document.querySelectorAll(".floating-menu-item").forEach(element => {
        element.classList.add("hidden");
    });
    document.getElementById("credits-box").classList.remove("hidden");
}
export function closeCredits() {
    document.querySelectorAll(".floating-menu-item").forEach(element => {
        element.classList.remove("hidden");
    });
    document.getElementById("credits-box").classList.add("hidden");
}

export function reviewLastPosition() {
    const gmc = document.getElementById("game-menu-container");
    gmc.classList.remove("fade-in-out");
    gmc.classList.add("hidden");
    setTimeout(() => { gmc.classList.add("fade-in-out"); }, 10);
    document.getElementById("console").classList.add("review-mode");
    document.getElementById("done-reviewing-button").classList.remove("hidden");
    document.getElementById("game-end-screen").classList.add("hidden");
}
export function finishReviewingLastPosition() {
    const ges = document.getElementById("game-end-screen");
    ges.classList.remove("hidden");
    const listener = () => {
        if (!ges.classList.contains("hidden")) {  // check that the transition finished
            document.getElementById("done-reviewing-button").classList.add("hidden");
        }
        document.getElementById("console").classList.remove("review-mode");
        ges.removeEventListener("transitionend", listener);
    };
    ges.addEventListener("transitionend", listener);

}

