
import {
    hideTitleScreen,
    hideGameMenuAndStartGame,
    stopGameAndShowGameMenu,
    hideGameEndScreenAndShowGameMenu,
    hideGameEndScreenAndStartGame,
    openOptionsMenu,
    updateInstaSwitching,
    openHowToPlay,
    openCredits,
    closeCredits,
    reviewLastPosition,
    finishReviewingLastPosition,
    closeOptionsMenu
} from "./main.js";

export default function addButtonClickListeners() {

    const hide = id => document.getElementById(id).classList.add("hidden");
    const unhide = id => document.getElementById(id).classList.remove("hidden");

    document.getElementById("play-button").addEventListener("click", hideTitleScreen);
    document.getElementById("game-menu-back-button").addEventListener("click", () => { unhide("title-screen"); });
    document.getElementById("how-to-play-button").addEventListener("click", openHowToPlay);
    document.getElementById("credits-button").addEventListener("click", openCredits);
    document.querySelector("#credits-box .close-button").addEventListener("click", closeCredits)
    document.getElementById("game-menu-start-button").addEventListener("click", hideGameMenuAndStartGame);
    document.getElementById("options-menu-button").addEventListener("click", openOptionsMenu);
    document.querySelector("#options-menu .close-button").addEventListener("click", closeOptionsMenu);
    document.getElementById("instaswitch-checkbox").addEventListener("click", updateInstaSwitching);
    document.getElementById("done-reviewing-button").addEventListener("click", finishReviewingLastPosition);
    document.getElementById("pause-button").addEventListener("click", () => { document.attackChess.game.togglePause(); });
    document.getElementById("restart-button").addEventListener("click", stopGameAndShowGameMenu);
    document.getElementById("play-again-button").addEventListener("click", hideGameEndScreenAndStartGame);
    document.getElementById("back-to-menu-button").addEventListener("click", hideGameEndScreenAndShowGameMenu);
    document.getElementById("review-last-position-button").addEventListener("click", reviewLastPosition);

}
