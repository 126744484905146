
const { Howl, Howler } = require("howler");


import beep from "url:/sounds/beep.mp3";
import explosion from "url:/sounds/explosion.mp3";
import scrape from "url:/sounds/scrape.mp3";
import scratch from "url:/sounds/scratch.mp3";

/*
 * temp_getSoundUrl() -- a temp function that converts a url to point at the
 * bundle asset it should point at
 *
 * This method temporarily replaces functionality until Parcel 2 gets file
 * globbing support. See https://github.com/parcel-bundler/parcel/pull/5933
 *
 */
function temp_getSoundUrl(url) {
    const mappings = {
        "/sounds/beep.mp3": beep,
        "/sounds/explosion.mp3": explosion,
        "/sounds/scrape.mp3": scrape,
        "/sounds/scratch.mp3": scratch,
    };
    return mappings[url];
}


// TODO: make into singleton
class SoundManager {

    constructor() {
        this.sounds = {};
    }

    loadSound(soundName) {
        // TODO: arg validation
        this.sounds[soundName] = new Howl({
            src: temp_getSoundUrl(`/sounds/${soundName}.mp3`),
            html5: true,
        });
    }

    playSound(soundName) {
        // TODO: arg validation
        if (! this.sounds[soundName])
            throw Error(`sound not loaded: ${soundName}`);

        this.sounds[soundName].play();
    }
}

export default SoundManager;

