
import bB from "url:/images/bB.png";
import bB_checked from "url:/images/bB_checked.png";
import bB_crossed from "url:/images/bB_crossed.png";
import bB_highlighted from "url:/images/bB_highlighted.png";
import bK from "url:/images/bK.png";
import bK_checked from "url:/images/bK_checked.png";
import bK_crossed from "url:/images/bK_crossed.png";
import bK_highlighted from "url:/images/bK_highlighted.png";
import bN from "url:/images/bN.png";
import bN_checked from "url:/images/bN_checked.png";
import bN_crossed from "url:/images/bN_crossed.png";
import bN_highlighted from "url:/images/bN_highlighted.png";
import bP from "url:/images/bP.png";
import bP_checked from "url:/images/bP_checked.png";
import bP_crossed from "url:/images/bP_crossed.png";
import bP_highlighted from "url:/images/bP_highlighted.png";
import bQ from "url:/images/bQ.png";
import bQ_checked from "url:/images/bQ_checked.png";
import bQ_crossed from "url:/images/bQ_crossed.png";
import bQ_highlighted from "url:/images/bQ_highlighted.png";
import bR from "url:/images/bR.png";
import bR_checked from "url:/images/bR_checked.png";
import bR_crossed from "url:/images/bR_crossed.png";
import bR_highlighted from "url:/images/bR_highlighted.png";
import wB from "url:/images/wB.png";
import wK from "url:/images/wK.png";
import wN from "url:/images/wN.png";
import wP from "url:/images/wP.png";
import wQ from "url:/images/wQ.png";
import wR from "url:/images/wR.png";

/*
 * temp_getImageUrl() -- a temp function that converts a url to point at the
 * bundle asset it should point at
 *
 * This method temporarily replaces functionality until Parcel 2 gets file
 * globbing support. See https://github.com/parcel-bundler/parcel/pull/5933
 *
 */
function temp_getImageUrl(url) {
    const mappings = {
        "/images/bB.png": bB,
        "/images/bB_checked.png": bB_checked,
        "/images/bB_crossed.png": bB_crossed,
        "/images/bB_highlighted.png": bB_highlighted,
        "/images/bK.png": bK,
        "/images/bK_checked.png": bK_checked,
        "/images/bK_crossed.png": bK_crossed,
        "/images/bK_highlighted.png": bK_highlighted,
        "/images/bN.png": bN,
        "/images/bN_checked.png": bN_checked,
        "/images/bN_crossed.png": bN_crossed,
        "/images/bN_highlighted.png": bN_highlighted,
        "/images/bP.png": bP,
        "/images/bP_checked.png": bP_checked,
        "/images/bP_crossed.png": bP_crossed,
        "/images/bP_highlighted.png": bP_highlighted,
        "/images/bQ.png": bQ,
        "/images/bQ_checked.png": bQ_checked,
        "/images/bQ_crossed.png": bQ_crossed,
        "/images/bQ_highlighted.png": bQ_highlighted,
        "/images/bR.png": bR,
        "/images/bR_checked.png": bR_checked,
        "/images/bR_crossed.png": bR_crossed,
        "/images/bR_highlighted.png": bR_highlighted,
        "/images/wB.png": wB,
        "/images/wK.png": wK,
        "/images/wN.png": wN,
        "/images/wP.png": wP,
        "/images/wQ.png": wQ,
        "/images/wR.png": wR,
    };
    return mappings[url];
}

/*
 * getPieceImagePath() -- gets an image path of the image specified by the args
 * args:
 *  - color: "w" or "b"
 *  - piece: one of "k", "q", "r", "b", "n", "p"
 *  - state (optional): one of "normal", "highlighted", "checked", "crossed"
 */
export function getPieceImagePath(color, piece, state) {
    // TODO: write better arg validation
    if (!color)
        throw new Error("color not given");
    if (!piece)
        throw new Error("piece not given");
    if (!state)
        state = "normal";  // TODO: improve this logic

    if (typeof color !== "string")
        throw new Error("color must be string");
    if (typeof piece !== "string")
        throw new Error("piece must be string");
    if (typeof state !== "string")
        throw new Error("state must be string");


    if (color.toLowerCase() !== "w" && color !== "b")
        throw new Error(`invalid color: "${color}"`);

    if ( ! ["k", "q", "r", "b", "n", "p"].includes(piece.toLowerCase()) )
        throw new Error(`invalid piece name: "${piece}"`);

    if (state != "normal") {
        if (color.toLowerCase() === "w")
            // probably TODO: support this
            throw new Error("different states for white pieces not supported");
        if ( ! ["normal", "highlighted", "checked", "crossed"].includes(state.toLowerCase()) )
            throw new Error(`invalid state name: "${state}"`);
    }

    // ok now we're done with validation, so here's the actual logic

    if (state == "normal")
        return temp_getImageUrl("/images/" + color.toLowerCase() + piece.toUpperCase()               + ".png");
    else
        return temp_getImageUrl("/images/" + color.toLowerCase() + piece.toUpperCase() + "_" + state + ".png");
}

