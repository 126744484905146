
export class LeaderboardManager {

    #scoresData;
    static #localStorageKey = "attackChessScores";

    constructor() {
        const defaultData = { scores: [] };
        const storedData = window.localStorage.getItem(LeaderboardManager.#localStorageKey);
        if (storedData) {
            this.#scoresData = JSON.parse(storedData);
        } else {
            window.localStorage.setItem(
                LeaderboardManager.#localStorageKey,
                JSON.stringify(defaultData)
            );
            this.#scoresData = defaultData;
        }
    }

    submitPersonalScore(score, accuracy) {
        this.#scoresData.scores.push({
            rawScore: score,
            accuracy: accuracy,
            adjustedScore: (score * accuracy),
            timestamp: Date.now()
        });
        window.localStorage.setItem(
            LeaderboardManager.#localStorageKey,
            JSON.stringify(this.#scoresData)
        );
    }

    getPersonalScoresData() {
        const clone = object => JSON.parse(JSON.stringify(object));
        return clone(this.#scoresData);
    }

    getPersonalScoresDataLastNDays(days) {
        const clone = object => JSON.parse(JSON.stringify(object));
        const tmp = clone(this.#scoresData);

        const N_DAYS_MS = 1000 * 60 * 60 * 24 * days;
        const now = Date.now();

        tmp.scores = tmp.scores.filter(scoreObject => {
            if (now - scoreObject.timestamp < N_DAYS_MS)
                return true;
            return false;
        })

        return tmp;
    }
}
