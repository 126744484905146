
class Timer {
    // TODO: consider getting rid of this superclass cuz it doesn't do anything useful
}


export class CountUpTimer extends Timer {

    #count_ms = 0;
    #lastStartTime;
    #running = false;

    constructor() {
        super();
    }

    start() {
        if (this.#running) throw new Error("start() called but timer is already running");
        this.#lastStartTime = Date.now();
        this.#running = true;
    }

    stop() {
        if (!this.#running) throw new Error("stop() called but timer is not running");
        this.#count_ms += (Date.now() - this.#lastStartTime);
        this.#running = false;
    }

    isRunning() {
        return this.#running;
    }

    reset() {
        if (this.#running) throw new Error("reset() called while timer still running");
        this.#count_ms = 0;
    }

    getTime() {
        if (this.#running)
            return this.#count_ms + (Date.now() - this.#lastStartTime);
        return this.#count_ms;
    }

    getTimeInSeconds() {
        return this.getTime() / 1000;
    }
}


export class CountDownTimer extends Timer {

    #running = false;
    #lastStartTime;
    #msRemainingOriginal;
    #msRemaining;
    #rate;

    constructor(ms) {
        super();
        if (! (ms > 0))
            throw new Error("ms must be a number greater than 0")
        this.#msRemainingOriginal = ms;
        this.#msRemaining = ms;
        this.#rate = 1.0;
    }

    start() {
        if (this.#running) throw new Error("start() called but timer is already running")
        this.#lastStartTime = Date.now();
        this.#running = true;
    }

    stop() {
        if (!this.#running) throw new Error("stop() called but timer is not running");
        this.#msRemaining -= (Date.now() - this.#lastStartTime) * this.#rate;
        if (this.#msRemaining < 0)
            this.#msRemaining = 0;
        this.#running = false;
    }

    isRunning() {
        return this.#running;
    }

    // TODO: decide whether we want this
    // this is allowed even when timer is running
    //reset() {
    //    this.#msRemaining = this.#msRemainingOriginal;
    //}

    getTimeRemaining() {
        let timeLeft = this.#msRemaining;
        if (this.#running)
            timeLeft -= (Date.now() - this.#lastStartTime) * this.#rate;

        if (timeLeft < 0)
            timeLeft = 0;

        return timeLeft;
    }

    getTimeRemainingInSeconds() {
        return this.getTimeRemaining() / 1000;
    }


    addTime(ms) {
        this.#msRemaining += ms;
    }
    subtractTime(ms) {
        this.#msRemaining -= ms;
    }

    setRate(rate) {
        if (this.#running) {
            this.stop();
            this.#rate = rate;
            this.start();
        } else {
            this.#rate = rate;
        }
    }
    getRate() { return this.#rate }
}

