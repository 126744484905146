
import { getPieceImagePath } from "./images.js";
const { Chess } = require("chess.js");

class Board {

    // These handle states for user interactions

    // #squareStates maps square names to states
    // each square can have a state that is one of
    //   "normal", "highlighed", "checked", or "crossed"
    #squareStates = {};
    // #squareTags maps square names to arrays of tags
    // (i.e., this.#squareTags[someSquareName] will give an array)
    // The array can contain any or none of the tags "clickable", ...  (more to be added)
    #squareTags = {};

    // #clickableSquareMousedownListeners is an array of listeners that we will call on each
    // mousedown on a clickable square. We pass the name of the clicked-on square to the
    // listener.
    #clickableSquareMousedownListeners = [];

    // #squareClickabilityStrategy is the square clickability strategy function. It takes a
    // square name and returns true/false as to whether the square should be
    // clickable (i.e. w/ piece highlightable and <style>cursor:pointer;</style>).
    // #squareClickabilityStrategy can be set with setSquareClickabilityStrategy().
    // This is used to tag squares with the "clickable" tag.
    // By default, make no squares clickable.
    #squareClickabilityStrategy = (squareName => false);

    // The chess.js object that will handle chess rules
    #chess = new Chess();

    // Whether the board is flipped
    #flipped = false;

    // Whether to fade the board out and in on setPosition()
    #animateSetPosition = false;

    #piecesHidden = false;

    #enabled = true;

    // The HTML <div> element that we will put the board UI in.
    #boardDiv;

    /*
     * The constructor creates the board inside the <div> given as a parameter.
     */
    constructor(boardDiv) {
        this.#boardDiv = boardDiv;
        this.#chess.clear();

        boardDiv.append(...Board.#generateBoard(false).childNodes);
        this.#boardDiv.style.transition = "opacity 0.4s cubic-bezier(0.65, 0, 0.35, 1)";

        // TODO: refactor this into different methods, like "highlightPiece()" or "unhighlightAll()"
        boardDiv.addEventListener("mousemove", e => {
            // TODO: fix this logic
            const element = document.elementFromPoint(e.clientX, e.clientY).parentElement;
            // if the moused-over element was a piece, the parent element will be a square
            if (element.classList.contains("board-square")) {
                // TODO: factor this (and code elsewhere) to use class names
                // instead of ids -- having two Boards on the same page should work
                const squareName = element.id;
                if (this.#squareStates[squareName] == "normal") {
                    //   (TODO: get rid of this "<exists> && <has particular tag>" bloat)
                    // if the square has tags and one of them is "clickable"
                    if (this.#squareTags[squareName] && this.#squareTags[squareName].includes("clickable")) {
                        // highlight the piece
                        this.setPieceState(squareName, "highlighted");
                    }
                }
                // remove other highlights from this.#squareStates
                for (const aSquareName in this.#squareStates) {
                    // TODO: standardize toLowerCase() usage, specifically for state names
                    if (this.#squareStates[aSquareName] === "highlighted" && aSquareName !== squareName) {
                        // TODO: DRY
                        this.setPieceState(aSquareName, "normal");
                    }
                }
            } else {
                for (const aSquareName in this.#squareStates) {
                    if (this.#squareStates[aSquareName] === "highlighted") {
                        this.setPieceState(aSquareName, "normal");
                    }
                }
            }
        });

        boardDiv.addEventListener("mousedown", e => {
            const element = document.elementFromPoint(e.clientX, e.clientY);

            let squareName;
            if (element.classList.contains("board-square"))
                squareName = element.id;
            else if (element.parentElement.classList.contains("board-square"))
                squareName = element.parentElement.id;
            else
                return;  // we don't need to do anything in this case

            // if the square is clickable
            if (this.#squareTags[squareName] && this.#squareTags[squareName].includes("clickable")) {
                if (! this.#piecesHidden)
                    this.#clickableSquareMousedownListeners.forEach(listener => listener(squareName));
            }
        });


        this.resize();
    } // end constructor()

    resize() {
        // TODO: debug this -- things bump up against the window edges when resizing vertically
        const boardParentStyle = window.getComputedStyle(this.#boardDiv.parentElement);
        const n = Math.min(
            parseFloat(boardParentStyle.width),
            parseFloat(boardParentStyle.height)
        );
        this.#boardDiv.style.height = (n - this.#getVerticalMarginInPixels())+"px";
        this.#boardDiv.style.width = (n - this.#getHorizontalMarginInPixels())+"px";
    }


    #animatingSetPosition = false;

    setPosition(fen) {
        if (!fen)
            throw new Error("must provide arg");
        if (! this.#chess.validate_fen(fen).valid)
            throw new Error(`invalid fen: "${fen}"`);

        if (this.#animatingSetPosition) {
            const msg = "cannot set new position before finishing animating the last one";
            throw new Error(msg);
        }

        const buildFromFen = fen => {
            this.#chess.load(fen);
            const board = this.#chess.board();
            // reset tags for all squares
            this.#squareTags = {};

            for (let i=7; i>=0; i--) {  // for each rank
                for (let j=0; j<8; j++) {  // for each square in the rank
                    if (board[7-i][j]) {
                        const color = board[7-i][j].color;
                        const piece = board[7-i][j].type;
                        this.#putPieceOnSquare(color, piece, ""+Board.#numToLetter(j)+(i+1));
                    } else {
                        this.#clearSquare(""+Board.#numToLetter(j)+(i+1));
                    }
                }
            }
        }

        if (this.#animateSetPosition) {  // here's what we do to animate:
            this.#animatingSetPosition = true;  // record state.

            this.#boardDiv.classList.add("hidden");  // start hide animation.
            this.#boardDiv.addEventListener("transitionend", () => {  // when it's done,
                buildFromFen(fen);  // change the position.

                this.#boardDiv.classList.remove("hidden");  // now, start the show animation.
                this.#boardDiv.addEventListener("transitionend", () => {  // when that's done,
                    this.#animatingSetPosition = false;  // record the state again.
                }, { once: true });
            }, { once: true });

        } else buildFromFen(fen);  // if we aren't animating, just change the position right away.
    }

    setAnimateSetPosition(animateSetPosition) {
        this.#animateSetPosition = animateSetPosition;
    }

    getAnimateSetPosition() { return this.#animateSetPosition; }

    clear() {
        this.#chess.clear();
        for (let i=7; i>=0; i--) {  // for each rank
            for (let j=0; j<8; j++) {  // for each square in the rank
                this.#clearSquare(""+Board.#numToLetter(j)+(i+1));
            }
        }
    }

    getPosition() {
        return this.#chess.fen();
    }

    flip() {
        // swap flipped board in
        const tmpFlippedBoard = Board.#generateBoard(!this.#flipped);
        this.#boardDiv.innerHTML = "";
        // TODO: rewrite this so it doesn't make a new big-text-display every time
        this.#boardDiv.innerHTML = '<div id="big-text-display"></div>';
        this.#boardDiv.append(...tmpFlippedBoard.childNodes);

        // put pieces on it
        const oldASP = this.#animateSetPosition;
        this.setAnimateSetPosition(false);
        this.setPosition(this.getPosition());
        this.setAnimateSetPosition(oldASP);

        this.#flipped = !this.#flipped;
    }

    isFlipped() { return this.#flipped; }

    getPieceOn(squareName) {
        return this.#chess.get(squareName);
    }


    /*
     * This function optionally takes the name of a
     * square. It returns an array of strings giving the moves that the piece on the
     * square (or, if that isn't given, on any and all squares) could be captured with
     * (as notation; e.g., "Bxc4")
     *
     * TODO: add more documentation for behavior here and for isPieceAttacked()
     * TODO: fix this for en passant
     */
    getAttacks(squareName) {
        // TODO: args validation
        if (squareName) {
            if (! this.#chess.get(squareName))
                throw new Error("getting attacking pieces for empty square not yet supported");

            return this.#chess.moves()
                .filter(moveStr => moveStr.includes("x") && moveStr.includes(squareName));
        } else {
            return this.#chess.moves()
                .filter(moveStr => moveStr.includes("x"));
        }
    }

    /*
     * This function calls getAttacks() to see whether a piece is attacked
     */
    isPieceAttacked(squareName) {
        return !!(this.getAttacks(squareName).length);
    }


    isSquareEmpty(squareName) {
        return !(this.#chess.get(squareName));
    }


    addClickableSquareMousedownListener(listener) {
        this.#clickableSquareMousedownListeners.push(listener);
    }

    setSquareClickabilityStrategy(strategy) {
        this.#squareClickabilityStrategy = strategy;
    }

    reloadSquareClickabilityStrategy() {
        // be lazy lol
        this.setPosition(this.getPosition());
    }



    hidePieces() {
        if (! this.#piecesHidden) {
            document.querySelectorAll(".chess-piece").forEach(piece => {
                piece.classList.add("hidden");
            });
            document.querySelectorAll(".board-square").forEach(square => {
                square.classList.remove("clickable-looking");
            });
            this.#piecesHidden = true;
        }
    }

    unhidePieces() {
        if (this.#piecesHidden) {
            document.querySelectorAll(".chess-piece").forEach(piece => {
                piece.classList.remove("hidden");
            });
            document.querySelectorAll(".board-square").forEach(square => {
                if (this.#squareTags[square.id] &&
                    this.#squareTags[square.id].includes("clickable"))
                    square.classList.add("clickable-looking");
            });
            this.#piecesHidden = false;
        }
    }

    toggleHidePieces() {
        if (this.#piecesHidden)
            this.unhidePieces();
        else
            this.hidePieces();
    }


    setEnabled(enabled) {
        this.#enabled = enabled;
        if (!this.#enabled) {
            this.#boardDiv.style.pointerEvents = "none";
            for (const squareName in this.#squareStates) {
                if (this.#squareStates[squareName] == "highlighted")
                    this.setPieceState(squareName, "normal");
            }
        } else
            this.#boardDiv.style.pointerEvents = "initial";
    }

    isEnabled() { return this.#enabled; }


    makeExplosion(squareName) {
        const FRAME_WIDTH = 75;
        const FRAMES_COUNT = 11;

        const square = document.getElementById(squareName);

        const explosion = document.createElement("div");
        explosion.classList.add("explosion");

        // scale explosion dimensions based on square size
        // TODO: update it on viewport resize
        const squareWidth = parseFloat(window.getComputedStyle(square).width);
        explosion.style.transformOrigin = "center";
        explosion.style.transform = `translate(-50%,-50%) scale(${squareWidth / FRAME_WIDTH})`;
        // (keep translation on .explosion from board css)

        square.appendChild(explosion);

        let frameNum = 1;
        const intervalRef = setInterval(() => {
            explosion.style.backgroundPositionX = `-${frameNum*FRAME_WIDTH}px`;
            frameNum++;

            if (frameNum >= FRAMES_COUNT) {
                clearInterval(intervalRef);
                explosion.remove();
            }
        }, 50);
    }


    static #generateBoard(flip) {
        if (flip == undefined)
            flip = false;

        // handy shortcut
        const mkDiv = (...classNames) => {
            const div = document.createElement("div");
            if (classNames.length > 0)
                div.classList.add(...classNames);
            return div;
        };

        const container = mkDiv();
        container.id = "board";

        // Here we build the board inside the container div.
        // There will be an 11x11 grid, giving room for
        //  - the chess board (8x8)
        //  - a border (we have 2 rows and 2 columns for that)
        //  - rank and file notation labels (1 row and 1 column).
        // From top to bottom, the rows will consist of:
        //  - row 1: spacer, border cell x 10
        //  - row 2-9: rank label, border cell, square x 8, border cell
        //  - row 10: spacer, border cell x 10
        //  - row 11: spacer (which is directly below the rank labels),
        //            spacer (which is directly below the left border cells),
        //            file label x 8,
        //            spacer (which is directly below the right border cells).


        // -- row 1 --
        // spacer
        // TODO: look at append() vs appendChild()
        container.appendChild(mkDiv("board-layout-spacer"));
        // border cell x 10
        container.appendChild(mkDiv("board-border", "board-top-left-corner"));
        for (let i=0; i<8; i++) {
            container.appendChild(mkDiv("board-border"));
        }
        container.appendChild(mkDiv("board-border", "board-top-right-corner"));

        // -- rows 2-9 --
        if (!flip) {
            for (let i=8; i>=1; i--) {
                // rank label
                const rankLabel = mkDiv(
                    "board-rank-label",
                    "board-label",
                    "outlined-text"
                );
                rankLabel.innerHTML = `${i}`;
                container.appendChild(rankLabel);
                // border cell
                container.appendChild(mkDiv("board-border"));
                // square x 8
                for (let j=0; j<8; j++) {
                    const square = mkDiv(
                        "board-square",
                        ((i+j) % 2 === 0) ? "white" : "black"
                    );
                    square.id = ( Board.#numToLetter(j)+(i) );
                    container.appendChild(square);
                }
                // border cell
                container.appendChild(mkDiv("board-border"));
            }
        } else {
            for (let i=1; i<=8; i++) {
                // rank label
                const rankLabel = mkDiv(
                    "board-rank-label",
                    "board-label",
                    "outlined-text"
                );
                rankLabel.innerHTML = `${i}`;
                container.appendChild(rankLabel);
                // border cell
                container.appendChild(mkDiv("board-border"));
                // square x 8
                for (let j=7; j>=0; j--) {
                    const square = mkDiv(
                        "board-square",
                        ((i+j) % 2 === 0) ? "white" : "black"
                    );
                    square.id = ( Board.#numToLetter(j)+(i) );
                    container.appendChild(square);
                }
                // border cell
                container.appendChild(mkDiv("board-border"));
            }

        }

        // -- row 10 --
        // spacer
        container.appendChild(mkDiv("board-layout-spacer"));
        // border cell x 10
        container.appendChild(mkDiv("board-border", "board-bottom-left-corner"));
        for (let i=0; i<8; i++)
            container.appendChild(mkDiv("board-border"));
        container.appendChild(mkDiv("board-border", "board-bottom-right-corner"));

        // -- row 11 --
        // spacer
        container.appendChild(mkDiv("board-layout-spacer"));
        // spacer
        container.appendChild(mkDiv("board-layout-spacer"));
        // file label x 8
        if (!flip) {
            for (let i=0; i<8; i++) {
                const fileLabel = mkDiv(
                    "board-file-label",
                    "board-label",
                    "outlined-text"
                );
                fileLabel.innerHTML = Board.#numToLetter(i);
                container.appendChild(fileLabel);
            }
        } else {
            for (let i=7; i>=0; i--) {
                const fileLabel = mkDiv(
                    "board-file-label",
                    "board-label",
                    "outlined-text"
                );
                fileLabel.innerHTML = Board.#numToLetter(i);
                container.appendChild(fileLabel);
            }
        }
        // spacer
        container.appendChild(mkDiv("board-layout-spacer"));


        // All done!
        return container;
    }

    #putPieceOnSquare(color, piece, squareName, state) {
        // TODO: arg validation

        let pieceElement = document.querySelector(`#${squareName} img`);
        if (!pieceElement) {
            pieceElement = document.createElement("img");
            pieceElement.classList.add("chess-piece");
            pieceElement.draggable = false;
            if (this.#piecesHidden)
                pieceElement.classList.add("hidden");

            document.getElementById(squareName).appendChild(pieceElement);
        }

        // TODO: rework this section

        // here, state can be undefined as it is an optional arg to this function
        pieceElement.src = getPieceImagePath(color, piece, state);
        this.#squareStates[squareName] = state || "normal";

        // (TODO: consider this as an optimization)
        //if ( (!this.#squareTags[squareName] || ! this.#squareTags[squareName].includes("clickable"))
        //    && this.#squareClickabilityStrategy(squareName) ) {
        if (this.#squareClickabilityStrategy(squareName)) {
            this.#setSquareClickable(squareName);
        } else {
            this.#squareTags[squareName] = new Array();
            this.#unsetSquareClickable(squareName);
        }
    }

    #clearSquare(squareName) {
        document.getElementById(squareName).innerHTML = "";
        this.#squareStates[squareName] = "normal";
        this.#squareTags[squareName] = new Array();
        this.#unsetSquareClickable(squareName);
    }

    // TODO: arg validation, here and everywhere
    // TODO: improve this
    setPieceState(squareName, state) {
        if (! this.#chess.get(squareName))
            throw new Error(`square "${squareName}" is empty`);

        if (state != this.#squareStates[squareName]) {
            this.#squareStates[squareName] = state;
            const color = this.#chess.get(squareName).color;
            const piece = this.#chess.get(squareName).type;
            this.#putPieceOnSquare(color, piece, squareName, state);
        }
    }

    // TODO: make sure this is used everywhere it could be
    #squareHasTag(squareName, tagName) {
        // TODO: confirm this doesn't need a null check for squareTags[squareName]
        return this.#squareTags[squareName].includes(tagName);
    }

    #addSquareTag(squareName, tagName) {
        if (!this.#squareTags[squareName]) {
            this.#squareTags[squareName] = new Array(tagName);
        } else if (! this.#squareTags[squareName].includes(tagName)) {
            this.#squareTags[squareName].push(tagName);
        }
    }

    #removeSquareTag(squareName, tagName) {
        if (this.#squareTags[squareName] && this.#squareTags[squareName].includes(tagName))
            this.#squareTags[squareName].splice(this.#squareTags[squareName].indexOf(tagName), 1);
    }

    #setSquareClickable(squareName) {
        this.#addSquareTag(squareName, "clickable");
        if (! this.#piecesHidden)
            document.getElementById(squareName).classList.add("clickable-looking");
    }

    #unsetSquareClickable(squareName) {
        this.#removeSquareTag(squareName, "clickable");
        document.getElementById(squareName).classList.remove("clickable-looking");
    }

    #getVerticalMarginInPixels() {
        const computedStyle = window.getComputedStyle(this.#boardDiv);
        return parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom) || 0;
    }

    #getHorizontalMarginInPixels() {
        const computedStyle = window.getComputedStyle(this.#boardDiv);
        return parseFloat(computedStyle.marginLeft) + parseFloat(computedStyle.marginRight) || 0;
    }


    static #numToLetter(num) {
        // TODO: maybe make this 1-indexed so the file labels code will be consistent with the rank labels code
        return {
            "0" : "a",
            "1" : "b",
            "2" : "c",
            "3" : "d",
            "4" : "e",
            "5" : "f",
            "6" : "g",
            "7" : "h",
        }[num];
    }

}

export default Board;

