
export function loadFile(pathOrUrl) {
    // TODO: arg validation
    return new Promise((resolve, reject) => {
        // send an HTTP request, whose reponse resolves the promise
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE)
                if (xhr.status === 200)
                    resolve(xhr.responseText);
                else
                    throw new Error(`non-200 status loading file: "${pathOrUrl}"`);
        };
        // send the request with a query string to avoid browser caching
        xhr.open("GET", pathOrUrl + ((/\?/).test(pathOrUrl) ? "&" : "?") + (new Date().getTime()));
        xhr.send();
    });
}

